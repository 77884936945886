<template>
  <div class="chart-container">
    <button class="breakdown-btn" v-if="showBreakdown" @click="togglebreakdown">
      <img
        v-if="!breakdown"
        class="eye-line"
        src="@/assets/eye-close-line.svg"
        alt=""
      />
      <img v-else class="eye-line" src="@/assets/eye-line.png" alt="" /><span
        class="breakdown-text"
        >Breakdown</span
      >
    </button>
    <pie-chart id="pie" :options="chartOptions" ref="pieChart"></pie-chart>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import { abbreviateNumber } from "js-abbreviation-number";
export default {
  name: "PieChart",
  components: {
    pieChart: Chart,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    eventLoader: {
      type: Object,
      default: () => {},
    },
    showBreakdown: {
      type: Boolean,
      default: false,
    },
    breakdown: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    annotation: {
      type: String,
      default: "%",
    },
    colors: {
      type: Array,
      default: () => [
        "#85edff",
        "#b5a9ff",
        "#DDDF00",
        "#24CBE5",
        "#64E572",
        "#FF9655",
        "#FFF263",
        "#6AF9C4",
      ],
    },
    legendDetails: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        colors: [
          "#85edff",
          "#b5a9ff",
          "#DDDF00",
          "#24CBE5",
          "#64E572",
          "#FF9655",
          "#FFF263",
          "#6AF9C4",
        ],
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          marginTop: 80,
          events: this.eventLoader,
        },
        title: {
          text: "",
          align: "left",
          style: { "font-weight": "bold", fontSize: "18px" },
          x: 10,
          y: 28,
        },
        tooltip: {
          formatter: function() {
            return (
              "Name: <b>" +
              this.point.name +
              "</b> </br> Value: <b>" +
              this.y +
              "</b>"
            );
          },
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },

        plotOptions: {
          pie: {
            animation: {
              duration: 1500,
            },
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              formatter: function(data) {
                return (
                  abbreviateNumber(this.y, 1, {
                    symbols: ["", "k", "M", "B", "T", "P", "E"],
                  }) + data.annotation
                );
              },
              enabled: true,
              distance: -30,
              annotation: this.annotation,
              style: {
                fontFamily: "ProximaNovaRegular",
                color: "black",
                fontSize: "14px",
              },
              fontFamily: "ProximaNovaRegular",
              // format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              // format: "<b>{point.y:.0f} " + this.annotation + "</b>",
            },
            showInLegend: true,
          },
        },
        series: [],
        credits: {
          enabled: false,
        },
        legend: {},
      },
    };
  },
  mounted() {
    this.chartOptions.title.text = this.title;
    this.chartOptions.series = this.series;
    this.chartOptions.colors = this.colors;
    this.chartOptions.chart.events = this.eventLoader;
  },
  methods: {
    togglebreakdown() {
      this.$emit("getBreakdown", !this.breakdown);
    },
  },
  created() {
    if (this.legendDetails !== undefined) {
      this.chartOptions.legend = this.legendDetails;
      return;
    }
    this.chartOptions.legend = {
      align: "left",
      verticalAlign: "bottom",
      layout: "horizontal",
    };
  },
  beforeUpdate() {
    if (this.legendDetails !== undefined) {
      this.chartOptions.legend = this.legendDetails;
      return;
    }
    this.chartOptions.chart.events = this.eventLoader;
    this.chartOptions.legend = {
      align: "left",
      verticalAlign: "bottom",
      layout: "horizontal",
    };
  },
};
</script>
<style scoped>
.chart-container {
  position: relative;
}
.breakdown-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 136px;
  height: 40px;
  background: #f3f3f3;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: hidden;
}
.breakdown-text {
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
}
.eye-line {
  width: 16px;
}
</style>
