<template>
  <div>
    <div class="page-wrapper chiller-theme" :class="{ toggled: isActive }">
      <div class="">
        <div>
          <div class="wrappere">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xs-12 mt-2">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-mb-12 pt-4">
                  <div class="card dp-card dp-card2">
                    <div
                      class="card-header d-flex"
                      style="justify-content: space-between"
                    >
                      <div class="w-50 text">Platform Wise Budget Split</div>
                    </div>
                    <w-columnChart
                      :chartData="platformWiseBudgetSplit"
                    ></w-columnChart>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 col-mb-12 pt-4">
                  <div class="card dp-card dp-card2">
                    <div
                      class="card-header d-flex"
                      style="justify-content: space-between"
                    >
                      <div class="w-50 text">
                        Channel Wise
                        {{
                          typeof selectedChannelSplit === "string"
                            ? selectedChannelSplit
                            : selectedChannelSplit.text
                        }}
                        Split
                      </div>
                      <div style="width: 17%">
                        <w-dropdown
                          :placeHolder="'Select'"
                          :options="ChannelDropdownOption"
                          @input="selectChannelSplit($event)"
                          :selectedOption="selectedChannelSplit"
                        ></w-dropdown>
                      </div>
                    </div>
                    <w-columnChart
                      :chartData="channelWiseImpressionSplit"
                    ></w-columnChart>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-mb-6 pt-4 pb-4">
                  <div class="card dp-card dp-card2">
                    <div class="card-header text">
                      AdType Wise Budget Split
                    </div>
                    <!--     <GChart
                          type="PieChart"
                          :data="adTypeWiseBudgetSplit"
                          :options="overallPieChartOptions"
                        /> -->

                    <PieChart
                      :series="adTypeWiseBudgetSplit"
                      :legendDetails="pieLegend"
                      :annotation="''"
                    />
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6 col-mb-6 pt-4 pb-4">
                  <div class="card dp-card dp-card2">
                    <div class="card-header text">
                      Channel Wise Conversion Split
                    </div>
                    <PieChart
                      :series="conversionWiseBudgetSplit"
                      :legendDetails="pieLegend"
                      :annotation="''"
                    />
                  </div>
                </div>
              </div>

              <div class="mb-5">
                <w-table
                  :tableProperties="tableDataValues"
                  :tableLoading="tableLoading"
                  class="platform"
                ></w-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <page-loader v-if="pageLoad"></page-loader>
    </div>
  </div>
</template>

<script>
import DataTable from "@/widgets/DataTable.vue";
import ColumnChart from "@/components/Chart/ColumnChart.vue";
import DropDown from "@/widgets/Dropdown.vue";
import { DigitalPlanning } from "@/services/DigitalPlanningServices.js";
import PieChart from "@/components/Chart/PieChart.vue";
import { abbreviateNumber } from "js-abbreviation-number";
import pageLoader from "@/widgets/PageLoader.vue";
const digitalPlanning = new DigitalPlanning();
export default {
  computed: {
    pageLoad() {
      if (
        this.tableDataValues.items.length > 1 &&
        this.adTypeWiseBudgetSplit[0].data.length > 1 &&
        this.channelWiseImpressionSplit.data.length > 1 &&
        this.conversionWiseBudgetSplit[0].data.length > 1 &&
        this.adTypeWiseBudgetSplit[0].data.length > 1
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    "w-table": DataTable,
    "w-columnChart": ColumnChart,
    "w-dropdown": DropDown,
    PieChart,
    pageLoader,
  },
  props: [],
  data() {
    return {
      tableLoading: false,
      pieLegend: {
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
      },
      tableDataValues: {
        fields: [
          {
            key: "rowDetails",
            label: "Platform Genere By AdType",
            thClass: "thClassLeft",
            tdClass: "text-left",
          },
          {
            key: "website",
            label: "Website",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "adType",
            label: "AdType",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "impressions",
            label: "Impressions",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "clicks",
            label: "Clicks",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "conversions",
            label: "Conversions",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          //   {
          //     key: "engagements",
          //     label: "Engagements",
          //     thClass: "thClassLeft",
          //     tdClass: "text-left",
          //     sortable: false,
          //   },
          //   {
          //     key: "reach",
          //     label: "Reach",
          //     thClass: "thClassLeft",
          //     tdClass: "text-left",
          //     sortable: false,
          //   },
          {
            key: "cpa",
            label: "CPA",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
          {
            key: "cost",
            label: "Cost",
            thClass: "thClassLeft",
            tdClass: "text-left",
            sortable: false,
          },
        ],
        items: [],
      },
      conversionWiseBudgetSplit: [
        {
          name: "Conversion wise based",
          colorByPoint: true,
          data: [],
        },
      ],
      adTypeWiseBudgetSplit: [
        {
          name: "AdType",
          colorByPoint: true,
          data: [],
        },
      ],
      channelWiseImpressionSplit: {
        data: [
          {
            name: "previous",
            data: [],
          },
          {
            name: "predicted",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: true,
        },
        xAxis: {
          // max: 0,
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#ccc",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "none",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      platformWiseBudgetSplit: {
        //scrollableWidth: 1200,
        data: [
          {
            name: "previous",
            data: [],
          },
          {
            name: "predicted",
            data: [],
          },
        ],
        title: {
          text: "",
          align: "left",
          margin: 50,
          style: {
            color: "#222a37",
            fontSize: "20px",
            fontFamily: "ProximaNovaBold",
          },
        },
        color: ["#67e8ff", "#b5a9ff", "#81f89e", "#ffdd7c", "#ffa2a2"],
        legend: {
          enabled: true,
        },
        xAxis: {
          // max: 4,
          categories: [],
          crosshair: {
            enabled: true,
          },
          scrollbar: {
            enabled: true,
            barBackgroundColor: "#ccc",
            barBorderRadius: 7,
            barBorderWidth: 0,
            buttonBackgroundColor: "white",
            buttonBorderWidth: 0,
            buttonArrowColor: "white",
            buttonBorderRadius: 0,
            rifleColor: "#F7FAFF",
            trackBackgroundColor: "white",
            trackBorderWidth: 0,
            trackBorderColor: "#FAFAFA",
            trackBorderRadius: 0,
            width: 50,
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "16px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
        yAxis: {
          visible: true,
          min: 0,
          title: {
            text: "",
          },
          gridLineDashStyle: "shortdash",
          gridLineWidth: 1,
          lineWidth: 1,
          labels: {
            style: {
              color: "#a9b6cd",
              fontSize: "14px",
              fontFamily: "ProximaNovaRegular",
            },
          },
        },
          plotOptions: {
          column: {
            // borderWidth: 0,
            // colorByPoint: true,
            dataLabels: {
            enabled: true,
            },
          },
          series: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return abbreviateNumber(this.y, 1, {
                  symbols: ["", "k", "M", "B", "T", "P", "E"],
                });
              },
            },
            // borderRadiusTopLeft: 25,
            // borderRadiusTopRight: 25,
          },
        },
    
        tooltip: {
          borderColor: "#FFFFFF",
          backgroundColor: "#050505",
          color: "#d0dbee",
          borderWidth: 0,
          style: {
            color: "#d0dbee",
          },
        },
      },
      isActive: false,
      padding: 0,
      menuMargin: 70,
      username: "Thomas",
      overallPieChartOptions: {
        // width:500,
        sliceVisibilityThreshold: 0,
        height: 300,
        pieHole: 0.5,
        chartArea: { left: 20, top: 50, width: "100%", height: "75%" },
        colors: [
          "#d92e72",
          "#05d0ef",
          "#fcba03",
          "ADFF2F",
          "#DA70D6",
          "BC8F8F",
          "#d92e72",
          "#ff9d4a",
          "#33ffcc",
          "#a62dc6",
        ],
        backgroundColor: "#3c4c66",
        pieSliceBorderColor: "none",
        pieSliceTextStyle: { color: "#fff", fontSize: 14 },
        pieSliceText: "value",
        legend: {
          position: "right",
          textStyle: { color: "#e5e5e5", fontSize: 14 },
          alignment: "center",
          labeledValueText: "value",
        },
        tooltip: {
          trigger: "true",
          showColorCode: true,
          text: "both",
        },
      },
      selectedChannelSplit: {id: "Impressions", text:"Impressions"},
      ChannelDropdownOption: [
        {
          id: "Impressions",
          text: "Impressions",
        },
        {
          id: "Clicks",
          text: "Clicks",
        },
      ],
      legendPositionNone: "none",
      externalColumnChartOptions: {
        // width: 300,
        bar: { groupWidth: 60 },
        height: 300,
        chartArea: {
          bottom: 40,
          top: 50,
        },
        annotations: {
          textStyle: {
            color: "#e5e5e5",
            fontSize: 13,
          },
        },
        vAxis: {
          format: "#'%'",
          //ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],

          textStyle: { color: "#e5e5e5" },
          baselineColor: "#e5e5e5",
        },
        hAxis: {
          textStyle: { color: "#e5e5e5" },
          baselineColor: "#e5e5e5",
        },
        colors: ["#d92e72"],
        backgroundColor: "#3c4c66",
        legend: "none",
      },
    };
  },
  methods: {
    getTableData(value) {
      this.tableDataValues.items = [];
      this.tableLoading = true;
      digitalPlanning.getPerformancePlatform(value).then((res) => {
        this.tableLoading = false;
        this.tableDataValues.items = res;
      });
    },
    getChannelSplit(value) {
      digitalPlanning.getPerformancePlatform(value).then((res) => {
        this.channelWiseImpressionSplit.data[0].data = [];
        this.channelWiseImpressionSplit.data[1].data = [];

        this.channelWiseImpressionSplit.xAxis.categories = [];

        for (var j = 0; j < res.length; j++) {
          this.channelWiseImpressionSplit.xAxis.categories.push(res[j].name);
        }

        for (var i = 0; i < res.length; i++) {
          this.channelWiseImpressionSplit.data[0].data.push(res[i].previous);
          this.channelWiseImpressionSplit.data[1].data.push(res[i].predicted);
        }
      });
    },
    getPlateformSplit(value) {
      digitalPlanning.getPerformancePlatform(value).then((res) => {
        this.platformWiseBudgetSplit.xAxis.categories = [];

        for (var j = 0; j < res.length; j++) {
          this.platformWiseBudgetSplit.xAxis.categories.push(res[j].name);
        }

        for (var i = 0; i < res.length; i++) {
          this.platformWiseBudgetSplit.data[0].data.push(res[i].previous);
          this.platformWiseBudgetSplit.data[1].data.push(res[i].predicted);
        }
      });
    },
    getadTypeSplit(value) {
      digitalPlanning.getPerformancePlatform(value).then((res) => {
        this.adTypeWiseBudgetSplit[0].data = [];
        // this.adTypeWiseBudgetSplit.push(["AdType", "Value"]);
        for (var i = 0; i < res.length; i++) {
          this.adTypeWiseBudgetSplit[0].data.push({
            name: res[i].name,
            y: res[i].value,
          });
        }
      });
    },
    getConversion(value) {
      digitalPlanning.getPerformancePlatform(value).then((res) => {
        this.conversionWiseBudgetSplit[0].data = [];
        this.conversionWiseBudgetSplit.push(["Conversion", "Value"]);
        for (var i = 0; i < res.length; i++) {
          this.conversionWiseBudgetSplit[0].data.push({
            name: res[i].name,
            y: res[i].value,
          });
        }
      });
    },

    alldata() {
      this.getChannelSplit("Clicks");
      this.getPlateformSplit("platform wise budget split");
      this.getadTypeSplit("ad type budget");
      this.getConversion("conversion");
      this.getTableData("table");
    },

    selectChannelSplit(e) {
      this.selectedChannelSplit = e;
      if (typeof e === String || undefined) {
        this.getChannelSplit(e);
      } else if (e.text != undefined) {
        this.getChannelSplit(e.text);
      }
    },
  },
  created() {
    this.alldata();
    this.username = sessionStorage.getItem("userName");
  },
};
</script>
<style scoped>
.wrapper {
  border-radius: 10px;
  background-color: #fff;
}
</style>
<style>
.platform table thead tr th {
  background-color: #fff;
}
.platform table thead tr {
  background-color: #fff;
}
.platform table {
  border: 0.1px solid;
}
.platform tbody tr:last-child {
  background: #fff;
}
.b-table-details tbody tr:nth-of-type(odd) {
  background: #fff;
}
.b-table-details tbody tr:nth-of-type(2n) {
  background: #fff;
}
.card-header {
  background: #fff;
  font-size: 20px;
  font-family: ProximaNovaBold;
  font-weight: bold;
  padding: 2rem;
  border-width: 0px;
}
.card {
  padding: 1rem;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}

.page-wrapper {
  margin-top: 100px;
}
</style>
